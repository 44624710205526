import React, {useState, useEffect, useContext, useRef} from 'react'

import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";
import BlurtBox from "components/BlurtBox.js";

import Register from "components/Register.js";
import Login from "components/Login.js";
import { TypeAnimation } from 'react-type-animation';
// import 'draft-js/dist/Draft.css';



export default function Landing() {
  
  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 pb-20 flex content-center items-center justify-center"
            style={{
              minHeight: "60vh"
            }}>
          <div className="absolute top-0 w-full h-full bg-center bg-cover"
              style={{
                // backgroundImage: "url('https://storage.needpix.com/rsynced_images/women-2835137_1280.png')"
              }}>
            <span id="blackOverlay" className="w-full h-full absolute opacity-5 bg-black"></span>
          </div>
          <div className="container relative mx-auto">
              <div className="items-center flex flex-wrap">
                <div className="w-full  px-4 ml-auto mr-auto text-center">
                  <div className="pr-12">
                  <br></br>
                  <div class="text-gray opacity-75 py-8 px-4 sm:px-6 ">
                    <br></br>

                  <h1 class="italic font-bold font-extrabold text-5xl ">
                    Do You Want to 
                    <span class="text-blue-500 font-bold px-4 ">
                    <TypeAnimation
                      sequence={[
                        'Improve your Memory', // Types 'One'
                        3000, // Waits 1s
                        'have a better focus', // Deletes 'One' and types 'Two'
                        3000, // Waits 2s
                        'Learn Faster', // Types 'Three' without deleting 'Two'
                        3000, // Waits 2s
                        'Increase your Attention', // Types 'Three' without deleting 'Two'
                        3000, // Waits 2s
                      ]}
                      // wrapper="span"
                      cursor={false}
                      repeat={Infinity}
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                    />
                      </span>?
                  </h1><br/>

                  {/* <h2 class="text-3xl leading-9 font-extrabold ">
                    Unlock the power of AI and <em class="text-sky-400/75">active recall</em> 
                  </h2> */}
                  <p class="mt-3 text-lg leading-7  ">
                    <em class="text-teal-500  font-bold">Active recall</em> is a research based technique that improves your focus and retention of information. By using it, you can increase your brain's capacity and capabilities.
                    Remember: if you can't put a concept into your own words, you haven't truly learned it. That's where <em class="text-indigo-500 font-bold">blurting</em> comes in. <em class="text-purple-500 font-bold">Blurting</em> is the most effective way to do active recall and has been used for centuries with great success.
                  </p>
                </div>
                    <br></br>
                    <div class="flex justify-center items-center">
                      {/* <div class="w-3/4 lg:w-1/2"> */}
                         {/* <div class="aspect-w-16 aspect-h-9"> */}
                            <iframe  src="https://www.youtube.com/embed/2fRXEzpyRak" width="560" height="315" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                         {/* </div>   */}
                      {/* </div> */}
                    </div>
                    <br></br>
                    <p class="mt-3 text-lg leading-7 ">
                    <b>Ready to take your learning to the next level?</b> Start using <a href="https://blurting.ai/" class="font-medium text-green-500 focus:outline-none focus:underline transition ease-in-out duration-150">blurting.ai</a> today and unleash the full power of your brain!
                  </p>

                  </div>
                </div>
              </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>


        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <Login></Login>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-10">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto ">
                <Register></Register>
                {/* <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                /> */}
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">
                    Start Your Free Account Today!
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    With a free account, you have access to the following features
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fas fa-fingerprint"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            Saving unlimited notes
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Blurting notes to make sure you still remember</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="far fa-paper-plane"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Sharing the list with your friends and classmates</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className=" ">
          <div className="container mx-auto ">
            <div className="flex flex-wrap justify-center text-center ">
              <div className="w-full lg:w-6/12 ">
                <h2 className="text-4xl font-semibold">
                  Do you want to try blurting now?
                </h2>
                <p className="text-lg leading-relaxed m-4 text-gray-600">
                   You can start in these three simple steps
                   <br></br><br></br>
                </p>
              </div>
             </div>
             <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Step 1</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Copy your notes, study guides or a part of textbook in the textbox below
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Step 2
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Try to remember what you provided in the previous step and blurt it out
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i className="fas fa-retweet "></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Step 3
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Study what you missed and try to blurt it again. you can adjust the text you wish to repeat. 
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <BlurtBox start_title="Let's start blurting .... copy and paste what you wish to learn"></BlurtBox>
            </div>
        </section>
        
      </main>
      <Footer />
    </>
  );
}
