import React, { useEffect, useState } from "react";
// justify-center 
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import { BrowserRouter as Router, useHistory, useLocation, Link } from "react-router-dom";

export default function Login() {

  const [email_address, setEmailAddress] = useState('')
  const [password, setPass] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [processing, setProcessing] = useState(false)
  let history = useHistory();

  useEffect(() => {
    axios.get(window.$server_address  + '/authenticated').then(response => {
      console.log(response)
      if (response.data.result==1){
        history.push("/main")
      } 
    }).catch( (error) =>
      console.log(error))


  },[])


  const loginSubmit = () => {
    setProcessing(true)
    axios.post(window.$server_address  + '/login', {
      password: password,
      email_address: email_address,
    }).then(response => {
      // console.log(response)
      if (response.data.result==1){
        history.push("/main")
      } else {
        setErrorMessage(response.data.message)

      }
      
      setProcessing(false)

    }).catch( (error) =>
      console.log(error))
};


  return (

          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
          {processing ? <LinearProgress/>: null}
            <div className="rounded-t mb-0 px-6 py-6">
              <div className="text-center mb-3">
                <h6 className="text-gray-600 text-sm font-bold">
                  Sign In
                </h6>
              </div>
              <hr className="mt-6 border-b-1 border-gray-400" />
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                    placeholder="Email"
                    onChange={(e) => setEmailAddress(e.target.value)}
                    style={{ transition: "all .15s ease" }}
                  />
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                    placeholder="Password"
                    onChange={(e) => setPass(e.target.value)}
                    style={{ transition: "all .15s ease" }}
                  />
                </div>
                {errorMessage && (
                <div className={`relative w-full ${errorMessage.includes('success') ? ' bg-green-100 text-green-500' : 'bg-red-100 text-red-500'} p-4 mb-3`}>
                  {errorMessage}
                </div>
                )}
                <div className="text-center mt-6">
                  <button
                    className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                    type="button"
                    style={{ transition: "all .15s ease" }}
                    onClick={e => loginSubmit(e)}

                  >
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>


  );
}


