
import UserDropdown from "./UserDropdown.js";
import React, { useEffect, useState } from "react";
// justify-center 
import axios from 'axios';
import { BrowserRouter as Router, useHistory, useLocation, Link } from "react-router-dom";

export default function Navbar() {
  let history = useHistory();
  useEffect(() => {
    axios.get(window.$server_address  + '/authenticated').then(response => {
      console.log(response)
      if (response.data.result==-2){
        history.push("/landing")
      } 
    }).catch( (error) =>
      console.log(error))


  },[])

  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <a
            className="text-black text-sm uppercase hidden lg:inline-block font-semibold"
            href="/main"
            // onClick={e => {history.push("/main")}}
          >
            Blurting.AI
          </a>
          {/* Form */}
          <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
            <div className="relative flex w-full flex-wrap items-stretch">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
              </span>
            </div>
          </form>
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}