import React, {useState, useEffect, useContext, useRef} from 'react'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import {Card,CardHeader,CardBody,FormGroup,Form,Input,InputGroupAddon,InputGroupText,
  InputGroup, Row, Image, Col, Container} from "reactstrap";
import { display } from '@mui/system';
import ScoreLine from './ScoreLine.js';
import Button from '@mui/material/Button';
import {Editor, EditorState} from 'draft-js';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";


export default function BlurtBox({text, start_title}) {

  // console.log({props})
  useEffect(e => {
    setMain_text(text)
  },[text])

  const [main_text, setMain_text] = useState(text) 
  const [base_text, setBase_text] = useState("") 
  const [base_text_hidden, setBase_text_hidden] = useState(false)
  const [answer_hidden, setAnswer_hidden] = useState(true)
  const [textlabel, setTextLabel] = useState(start_title)
  const [blurt_state, setBlurt_state] = useState('init')
  const [blurt_button_caption, setBlurt_Button_caption] = useState('Start Blurting...')
  const [result_text, setResult_text] = useState([{'sentence':'weddwe','score':0.01}])
  const [response_data, setResponseData] = useState(null);
  const [slider_value, SetSliderValue] = useState(100)
  const [slider_color, SetSlider_color] = useState('red')
  const [processing, setProcessing] = useState(false)
  const [error_value, setError_value] = useState("")
  const handleSubmit = () => {

      axios.post(window.$server_address +'/compare', {
        base_text: base_text,
        answer: main_text,
        nugget_id: window.location.pathname.split('/nugget/')[1] 
      }).then(response => {
        console.log(response)
        setResponseData(response.data.result)      
        const sent_scores = response.data.result
        // console.log()
        SetSliderValue(Math.max(...sent_scores.map(key => key.score))* 100)
        setResult_text(sent_scores)
        setTextLabel("The comparison result between your notes and blurting ...")
        setBlurt_state("result")
        setAnswer_hidden(false)
        setBlurt_Button_caption("Continue Blurting")
        setBase_text_hidden(true)
        setMain_text("")
        setProcessing(false)


      }).catch( (error) =>
        console.log(error))

  };


  // useEffect(() => {
  //   response_data.map(key => {
  //     console.log(key.sentence)
  //     console.log(key.score)
  //     // console.log(data[key])
  //   })
  // }
  //   , response_data)

  const blurt_button_clicked = () => {
    setError_value("")
    if (blurt_state=='init'){
      // alert(base_text)
      if (main_text==''){
        setError_value("Please write something about what you want to blurt about")
        return
      }

      setBase_text(main_text)
      setMain_text("")
      setTextLabel("Try to recall what you read and write them down ")
      setBlurt_state("blurt")
      setBlurt_Button_caption("Compare")
      
    }
    if (blurt_state=='blurt'){
      if (main_text==''){
        setError_value("Please jot down your blurting below ....")
        return
      }

      setProcessing(true)
      handleSubmit()      
      SetSliderValue(100)

    }
    if (blurt_state=='result'){
      setBase_text_hidden(false)
      setAnswer_hidden(true)
      setMain_text(result_text.map((a) => a.sentence).join("."))
      renderColoredText("")
      setTextLabel("Feel free to modify the text or copy and paste a new text")
      setBlurt_state("init")
      setBlurt_Button_caption("Start Blurting")

      
    }
    
  }
  
  function SliderChange(e){
    setResult_text(response_data.filter(score => score.score < e/100))
    SetSlider_color(getColorForChar(e/100))
    SetSliderValue(e)
  }

  function getColorForChar(score) {
    // return the color based on the character
    // e.g. return 'red' for vowels, 'green' for consonants, etc.
    const red = Math.round(255 - (score * 255));
    const green = Math.round(score * 255);
    const backgroundColor = `rgb(${red}, ${green}, 0)`
    return backgroundColor
  }
  
  function renderColoredText() {
    
    return result_text.map(item => (
      <span  style={{ color: getColorForChar(item.score) }}>
        {item.sentence}
      </span>
    ));
  }

  const marks = [
    {
      value: 50,
      label: 'Missed',
    },
    {
      value: 90,
      label: 'Good Job',
    },
    {
      value: 100,
      label: 'Perfect',
    },
  ];
  
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const microphoneRef = useRef(null);


  useEffect(()=>{
    if (blurt_state == 'blurt'){
      setMain_text(transcript)
    }
    // console.write("ef")
  },[transcript])


  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return (
      <div className="mircophone-container">
        Browser is not Support Speech Recognition.
      </div>
    );
  }
  const handleListing = () => {
    setIsListening(true);
    microphoneRef.current.classList.add("listening");
    SpeechRecognition.startListening({
      continuous: true,
    });
  };
  const stopHandle = () => {
    setIsListening(false);
    microphoneRef.current.classList.remove("listening");
    SpeechRecognition.stopListening();
  };
  const handleReset = () => {
    stopHandle();
    resetTranscript();
  };

 

  return (
    <div className="flex flex-wrap justify-center  py-10">
    <div className="w-full lg:w-10/12 ">
      <Box hidden={!processing}>
    <LinearProgress  />
    </Box>





    
    
    
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
        <div className="flex-auto p-5 lg:p-10">
          <h4 className="text-2xl font-semibold">
          {textlabel}
          </h4>
          <p className="leading-relaxed mt-1 mb-4 text-gray-600" style={{color:'red'}}>
          {/* <b> */}
            {error_value}
            {/* </b> */}
          </p>

          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="message"
            >


            </label>
            <textarea
              rows="12"
              cols="80"
              maxlength="4000"
              className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
              placeholder="Your text goes right here ..."
              value={main_text}
              onChange={e => setMain_text(e.target.value)}
              hidden={base_text_hidden}
            />
          </div>
          <div hidden={answer_hidden}>
            <Slider marks={marks} value={slider_value} defaultValue={slider_value} onChange={(e) => SliderChange(e.target.value)} aria-label="Default" valueLabelDisplay="auto" />
            <ScoreLine />
            <div style={{'margin-top':50, 'background-color':'white',
             'border':'2px black solid', 'border-radius':10, 'padding':10, minHeight: "40vh"}} >
            {renderColoredText()}
            </div>
          </div>
          <div className="text-center mt-6">




            {blurt_state == 'blurt' &&
              <span>
                    {!isListening && (
                    <button
                      className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      ref={microphoneRef}
                      onClick={handleListing}
                    >
                      Record
                    </button>)}
                    {isListening && (
                      <button className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"onClick={stopHandle}>
                        Stop
                      </button>
                    )}
                   {transcript && (
                      <button className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1" onClick={handleReset}>
                        Reset
                      </button>
                   )}
             </span>   
            }
          



            <button
              className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              disabled={processing}
              onClick={blurt_button_clicked}
              style={{ transition: "all .15s ease" }}
            >
              {blurt_button_caption}
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>

    );
}
