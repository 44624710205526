import React, {useState, useEffect, useContext, useRef} from 'react'
import Topbar from "components/Topbar.js";
import BlurtBox from 'components/BlurtBox';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { InputLabel, Typography } from '@material-ui/core';
import { BrowserRouter as Router, useHistory, useLocation, Link } from "react-router-dom";


export default function Blurt() {
    console.log(window.location.pathname.split('/blurt/')[1])
    const [loading, setLoading] = useState(true);
    // setLoading(true)
    let history = useHistory();
    const [nugget, setNugget] = useState( {
        body_text: "",
        embedded_link: "",
        notes: "",
        tags: "",
        title: "",
        topic: "",
        updated_time: ""
      });


    useEffect(() => {
        axios.post(window.$server_address + '/get_nugget_by_nugget_id',
        {'nugget_id':window.location.pathname.split('/nugget/')[1] })
          .then(response => {
            // setData(response.data);
            // setBlurtText(response.data[0]['body_text'])
            console.log(response.data[0])
            setNugget(response.data[0])
            setLoading(false)
          })
          .catch(error => {
            console.error(error);
          });
      },[]);

    const handle_delete = () => {
        axios.post(window.$server_address + '/delete_nugget',
        {'nugget_id':window.location.pathname.split('/nugget/')[1] })
        .then(response => {
            console.log('Nugget deleted successfully');
            // handle any additional logic here if needed
            history.push("/main")
        })
        .catch(error => {
            console.error('Error deleting nugget:', error);
            // handle any errors here if needed
        });
    }

   
    
    return (
        <div>
            <Topbar/>
            <section className="py-20">
            <div className="container mx-auto ">
            {loading &&                 
                     <Stack spacing={1} style={{ width: '100%' }} className="py-20">
                      <Skeleton variant="rectangular" width='100%' height={60} />
                      <Skeleton variant="rectangular" width='100%' height={60} />
                      <Skeleton variant="rectangular" width='100%' height={60} />
                      <Skeleton variant="rectangular" width='100%' height={60} />
                      <Skeleton variant="rectangular" width='100%' height={60} />
                    </Stack>}
            {!loading && 
                <div className=" justify-left ">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <InputLabel className="mr-4">Topic</InputLabel>
                                <Typography className="py-1">{nugget.topic}</Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <InputLabel className="mr-4">Sub Topic</InputLabel>
                                <Typography className="py-1">{nugget.sub_topic}</Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <InputLabel className="mr-4">Title</InputLabel>
                                <Typography className="py-1">{nugget.title}</Typography>
                            </div>
                            <br/>
                            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/8IlzKri08kk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                            <div dangerouslySetInnerHTML={{ __html: nugget.embedded_link }} />
                            

                            <BlurtBox text={nugget.body_text}></BlurtBox>
                            {nugget.notes !="" &&
                            <div>
                                <InputLabel className="mr-4">Notes</InputLabel>
                                <Typography className="py-1">{nugget.notes}</Typography>
                            </div>
                            }
                            <div class="my-6">
                                {/* <button
                                    className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    style={{ transition: "all .15s ease" }}> Previous
                                </button>  
                                <button
                                    className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    style={{ transition: "all .15s ease" }}> Next
                                </button>  
                                <button
                                    className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    style={{ transition: "all .15s ease" }}> Edit
                                </button>   */}
                                <button
                                    className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={() => history.push("/main")}
                                    style={{ transition: "all .15s ease" }}> Return
                                </button>  
                                <button
                                    className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={handle_delete}
                                    style={{ transition: "all .15s ease" }}> Delete
                                </button>  

                            </div>

                </div>}
            </div>
            </section>
        </div>
    )
}