import React, {useState, useEffect, useContext, useRef} from 'react'
import { BrowserRouter as Router, useHistory, useLocation, Link } from "react-router-dom";

import Topbar from "components/Topbar.js";
import { TagsInput } from "react-tag-input-component";
import axios from 'axios';

export default function NewBlurt() {
    let history = useHistory();
    const [selected, setSelected] = useState([]);
    useEffect(e=>{
        setFormData((prevFormData) => ({
            ...prevFormData,
            ['tags']: selected.join(",")
          }));
    },
    [selected])
    const [formData, setFormData] = useState({
        topic: '',
        sub_topic: '',
        title: '',
        body_text: '',
        embedded_link: '',
        tags: '',
        recall: 0,
        forget: 0,
        notes: ''
      });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value
        }));
      };

    const handleSubmit = () => {
        // setProcessing(true)
        axios.post(window.$server_address + '/insert_nugget', formData)
        .then(response => {
          // console.log(response)
          history.push("/main")
        //   setProcessing(false)
    
        }).catch( (error) =>
          console.log(error))
      };

    return (
        <div>
            <Topbar/>
            <section className="relative py-20">
            <div className="container mx-auto ">
                <div className="flex flex-wrap  text-center ">
                <div className="relative w-full mb-3 mt-6">
                    <input
                            type="text"
                            name='topic'
                            className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                            placeholder="Topic"
                            onChange={handleChange}
                            style={{ transition: "all .15s ease" }}
                    />                
                </div>
                <div className="relative w-full mb-3 mt-6">
                    <input
                            type="text"
                            name='sub_topic'
                            className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                            placeholder="Sub Topic"
                            onChange={handleChange}
                            style={{ transition: "all .15s ease" }}
                    />                
                </div>
                <div className="relative w-full mb-3 mt-6">
                    <input
                            type="text"
                            name='title'
                            className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                            placeholder="Title"
                            onChange={handleChange}
                            style={{ transition: "all .15s ease" }}
                    />                
                </div>
                <div className="relative w-full mb-3 mt-6">
                    <input
                            type="text"
                            name='embedded_link'
                            className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                            placeholder="Embedded Link"
                            onChange={handleChange}
                            style={{ transition: "all .15s ease" }}
                    />                
                </div>

                <div className="relative w-full mb-3 mt-6">
                    <textarea
                        rows="12"
                        cols="80"
                        maxlength="4000"
                        name='body_text'
                        onChange={handleChange}
                        className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Text"
                    />  
                </div>
                <div className="relative w-full mb-3 mt-6">
                <TagsInput
                    value={selected}
                    // onChange={e => {handleChange(e);setSelected(e)}}
                    onChange={setSelected}
                    name="tags"
                    placeHolder="Enter Tags + (Enter)"
                /> 
                <div className="relative w-full mb-3 mt-6">
                    <textarea
                        rows="3"
                        cols="80"
                        onChange={handleChange}
                        maxlength="4000"
                        name='notes'
                        className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Notes"
                    />  
                </div>

                </div>

                    <div className="ext-left mt-6">

                        <button
                        className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        onClick={e => handleSubmit(e)}
                        
                        style={{ transition: "all .15s ease" }}> Create
                        </button>

                        <button
                        className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        onClick={() => history.push("/main")}
                        style={{ transition: "all .15s ease" }}> Cancel
                        </button>

                    </div>
                </div>
            </div>
            </section>
        </div>
    )
}