import React from 'react';

const ScoreLine = ({ score }) => {

  function getColorForChar(score) {
    // return the color based on the character
    // e.g. return 'red' for vowels, 'green' for consonants, etc.
    const red = Math.round(255 - (score * 255));
    const green = Math.round(score * 255);
    const backgroundColor = `rgb(${red}, ${green}, 0)`
    return backgroundColor
  }

  const numer_of_div= 20
  const sectionStyles = [];
  for (let i = 0; i <= numer_of_div-1; i++) {
    const style = {
      width: (1/numer_of_div * 100) + `%`,
      height: '5px',
      backgroundColor: getColorForChar(i/numer_of_div),
      display: 'inline-block',
    };
    sectionStyles.push(style);
  }

  return (
    <div style={{'margin-top': '-61px'}}>
      {sectionStyles.map((style, index) => (
        <div key={index} style={style} />
      ))}
    </div>
  );
};

export default ScoreLine;
