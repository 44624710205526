
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col, Container
} from "reactstrap";
import { react } from "@babel/types";
import { useState, useEffect, useContext } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";


const PrivacyTerms = () => {
 

        

  return (
    <>
        <section className="relative py-20">
            <div className="container mx-auto ">
      <Container className="">
        <Row>
        <h1>Privacy Policy </h1>
        </Row>
        </Container>
      <Container className="">
        <Row>
    <p class="c57">Marj inc.<span class="c1">&nbsp;(&ldquo;</span><span
            class="c0">Company</span><span class="c1">,&rdquo; &ldquo;</span><span class="c0">we</span><span
            class="c1">,&rdquo; or &ldquo;</span><span class="c0">us</span><span class="c1">&rdquo;) respects your
            privacy and is committed to protecting it through this Privacy Policy. </span></p>
    <p class="c48"><span class="c1">This Privacy Policy governs your access to and use of </span><span
            class="c9">www.blurting.ai</span><span class="c1">, including any content, functionality and services
            offered on or through </span><span class="c9">www.blurting.ai</span><span class="c1">(the
            &quot;</span><span class="c0">Website</span><span class="c1">&quot;), whether as a guest or a registered
            user. </span></p>
    <p class="c49"><span class="c1">When accessing the Website, the Company will learn certain information about you,
            both automatically and through voluntary actions you may take, during your visit. This policy applies to
            information we collect on the Website and in email, text, or other electronic messages between you and the
            Website. </span></p>
    <p class="c30"><span class="c1">Please read the Privacy Policy carefully before you start to use the Website.
        </span><span class="c0">By using the Website or by clicking to accept or agree to the Terms of Use when this
            option is made available to you, you accept and agree to be bound and abide by the Privacy Policy.
        </span><span class="c1">If you do not want to agree to the Privacy Policy, you must not access or use the
            Website. </span></p>
    <p class="c22"><span class="c0">Children Under The Age Of 13 </span></p>
    <p class="c11"><span class="c1">Our Website is not intended for children under 13 years of age. No one under age 13
            may provide any information to or on the Website. We do not knowingly collect personal information from
            children under 13. If you are under 13, do not use or provide any information on this Website or on or
            through any of its features/register on the Website, make any purchases through the Website, use any of the
            interactive or public comment features of this Website or provide any information about yourself to us,
            including your name, address, telephone number, email address, or any screen name or user name you may use.
        </span></p>
    <p class="c31"><span class="c1">If we learn we have collected or received personal information from a child under 13
            without verification of parental consent, we will delete that information. If you believe we might have any
            information from or about a child under 13, please contact us at </span><span
            class="c9">info@marjsystems.com</span><span class="c1">. </span></p>
    <p class="c8"><span class="c0">Information We Collect About You </span></p>
    <p class="c26"><span class="c1">When you access the Website, the Company will learn certain information about you
            during your visit. </span></p>
    <p class="c24"><span class="c9 c27">Information You Provide To Us</span><span class="c1">. The Website provides
            various places for users to provide information. We collect information that users provide by filling out
            forms on the Website, communicating with us via contact forms, responding to surveys, search queries</span>
    </p>
    <p class="c23"><span class="c1">on our search feature, providing comments or other feedback, and providing
            information when ordering a product or service via the Website. </span></p>
    <p class="c12"><span class="c1">We use information you provide to us to deliver the requested product and/or
            service, to improve our overall performance, and to provide you with offers, promotions, and information.
        </span></p>
    <p class="c40"><span class="c27 c9">Information We Collect Through Automatic Data Collection Technology</span><span
            class="c1">. As you navigate through our Website, we may use automatic data collection technologies
            including Google Analytics to collect certain information about your equipment, browsing actions, and
            patterns. This will generally include information about your location, your traffic pattern through our
            website, and any communications between your computer and our Website. Among other things, we will collect
            data about the type of computer you use, your Internet connection, your IP address, your operating system,
            and your browser type. </span></p>
    <p class="c56"><span class="c1">The information we collect automatically is used for statistical data and will not
            include personal information. We use this data to improve our Website and our service offerings. To the
            extent that you voluntarily provide personal information to us, our systems will associate the automatically
            collected information with your personal information. </span></p>
    <p class="c8"><span class="c0">Use of Cookies And Pixels </span></p>
    <p class="c18"><span class="c1">Similar to other commercial websites, our website utilizes a standard technology
            called &ldquo;cookies&rdquo; and server logs to collect information about how our site is used. Information
            gathered through cookies and server logs may include the date and time of visits, the pages viewed, time
            spent at our site, and the websites visited just before and just after our own, as well as your IP address.
        </span></p>
    <p class="c37"><span class="c1">A cookie is a very small text document, which often includes an anonymous unique
            identifier. When you visit a website, that site&rsquo;s computer asks your computer for permission to store
            this file in a part of your hard drive specifically designated for cookies. Each website can send its own
            cookie to your browser if your browser&rsquo;s preferences allow it, but (to protect your privacy) your
            browser only permits a website to access the cookies it has already sent to you, not the cookies sent to you
            by other sites. </span></p>
    <p class="c25"><span class="c1">The Company reserves the right to use technological equivalents of cookies,
            including social media pixels. These pixels allow social media sites to track visitors to outside websites
            so as to tailor advertising messages users see while visiting that social media website. The Company
            reserves the right to use these pixels in compliance with the policies of the various social media sites.
        </span></p>
    <p class="c22"><span class="c0">Third Party Use Of Cookies </span></p>
    <p class="c46"><span class="c1">Some content or applications, including advertisements, on the Website are served by
            third-parties, including advertisers, ad networks and servers, content providers, and</span></p>
    <p class="c16"><span class="c1">application providers. These third parties may use cookies alone or in conjunction
            with web beacons or other tracking technologies to collect information about you when you use our website.
            The information they collect may be associated with your personal information or they may collect
            information, including personal information, about your online activities over time and across different
            websites and other online services. They may use this information to provide you with interest-based
            (behavioral) advertising or other targeted content. </span></p>
    <p class="c45"><span class="c1">We do not control these third parties&#39; tracking technologies or how they may be
            used. If you have any questions about an advertisement or other targeted content, you should contact the
            responsible provider directly. </span></p>
    <p class="c8"><span class="c0">Email Information </span></p>
    <p class="c51"><span class="c1">If you choose to correspond with us through email, we may retain the content of your
            email messages together with your email address and our responses. We provide the same protections for these
            electronic communications that we employ in the maintenance of information received online, mail, and
            telephone. This also applies when you register for our website, sign up through any of our forms using your
            email address or make a purchase on this site. For further information see the email policies below. </span>
    </p>
    <p class="c8"><span class="c0">Email Policies </span></p>
    <p class="c64"><span class="c1">We are committed to keeping your e-mail address confidential. We do not sell, rent,
            or lease our subscription lists to third parties, and will not disclose your email address to any third
            parties except as allowed in the section titled Disclosure of Your Information. </span></p>
    <p class="c58"><span class="c1">We will maintain the information you send via e-mail in accordance with applicable
            federal law. </span></p>
    <p class="c2"><span class="c1">In compliance with the CAN-SPAM Act, all e-mails sent from our organization will
            clearly state who the e-mail is from and provide clear information on how to contact the sender. In
            addition, all e-mail messages will also contain concise information on how to remove yourself from our
            mailing list so that you receive no further e-mail communication from us. </span></p>
    <p class="c29"><span class="c1">Our emails provide users the opportunity to opt-out of receiving communications from
            us and our partners by reading the unsubscribe instructions located at the bottom of any e-mail they receive
            from us at anytime. </span></p>
    <p class="c61"><span class="c1">Users who no longer wish to receive our newsletter or promotional materials may
            opt-out of receiving these communications by clicking on the unsubscribe link in the e-mail. </span></p>
    <p class="c8"><span class="c0">How And Why We Collect Information</span></p>
    <p class="c55"><span class="c1">The Company collects your information in order to record and support your
            participation in the activities you select. If you register to download a book or resources, sign up for our
            newsletter, and/or purchase a product from us, we collect your information. We use this information to track
            your preferences and to keep you informed about the products and services you have selected to receive and
            any related products and/or services. As a visitor to this Website, you can engage in most activities
            without providing any personal information. It is only when you seek to download resources and/or register
            for services that you are required to provide information. </span></p>
    <p class="c44"><span class="c1">If you are outside the European Union and opt to receive any free resources,
            participate in any free training programs, register for a webinar, register for a live event, register for a
            seminar, or purchase any products sold by the Company on this Website, we will automatically enroll you to
            receive our free email newsletter. If you do not wish to receive this newsletter, you can unsubscribe
            anytime. We include an &ldquo;unsubscribe&rdquo; link at the bottom of every email we send. If you ever have
            trouble unsubscribing, you can send an email to info@marjsys</span><span class="c9">tems.com </span><span
            class="c1">requesting to unsubscribe from future emails. </span></p>
    <p class="c54"><span class="c1">If you are in the European Union and opt to receive any free resources, participate
            in any free training programs, register for a webinar, register for a live event, register for a seminar, or
            purchase any products sold by the Company on this Website, we will only enroll you to receive our free email
            newsletter if you affirmatively consent to it. If you do not wish to receive this newsletter, you can
            unsubscribe anytime. We include an &ldquo;unsubscribe&rdquo; link at the bottom of every email we send. If
            you ever have trouble unsubscribing, you can send an email to </span><span class="c9 c63"><a class="c35"
                href="mailto:info@marjsystems.com">info@marjsystems.com</a></span><span class="c9">&nbsp;</span><span
            class="c1">requesting to unsubscribe from future emails. </span></p>
    <p class="c8"><span class="c0">How Do We Use the Information That You Provide to Us? </span></p>
    <p class="c7"><span class="c1">We use personal information for purposes of presenting our Website and its contents
            to you, providing you with information, providing you with offers for products and services, providing you
            with information about your subscriptions and products, carrying out any contract between you and the
            Company, administering our business activities, providing customer service, and making available other items
            and services to our customers and prospective customers. </span></p>
    <p class="c65"><span class="c1">From time-to-time, we may use the information you provide to us to make you offers
            to purchase products and services provided by third parties in exchange for a commission to be paid to us by
            such third parties. Should you opt to take part in such promotions, the third parties will receive your
            information. </span></p>
    <p class="c21"><span class="c1">From time-to-time, we may use the information you provide to us to display
            advertisements to you that are tailored to your personal characteristics, interests, and activities. </span>
    </p>
    <p class="c19"><span class="c0">Disclosure Of Your Information</span></p>
    <p class="c36"><span class="c1">As a general rule, we do not sell, rent, lease or otherwise transfer any information
            collected whether automatically or through your voluntary action. </span></p>
    <p class="c15"><span class="c1">We may disclose your personal information to our subsidiaries, affiliates, and
            service providers for the purpose of providing our services to you. </span></p>
    <p class="c39"><span class="c1">We may disclose your personal information to a third party, including a lawyer or
            collection agency, when necessary to enforce our terms of service or any other agreement between you and the
            Company. </span></p>
    <p class="c59"><span class="c1">We may provide your information to any successor in interest in the event of a
            merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of
            the Company&rsquo;s assets and/or business. </span></p>
    <p class="c4"><span class="c1">We may disclose information when legally compelled to do so, in other words, when we,
            in good faith, believe that the law requires it or for the protection of our legal rights or when compelled
            by a court or other governmental entity to do so. </span></p>
    <p class="c8"><span class="c0">How Do We Protect Your Information and Secure Information Transmissions? </span></p>
    <p class="c34"><span class="c1">We employ commercially reasonable methods to ensure the security of the information
            you provide to us and the information we collect automatically. This includes using standard security
            protocols and working only with reputable third-party vendors. </span></p>
    <p class="c14"><span class="c1">Email is not recognized as a secure medium of communication. For this reason, we
            request that you do not send private information to us by email. However, doing so is allowed, but at your
            own risk. Some of the information you may enter on our website may be transmitted securely via a secure
            medium known as Secure Sockets Layer, or SSL. Credit Card information and other sensitive information is
            never transmitted via email. </span></p>
    <p class="c13"><span class="c1">The Company may use software programs to create summary statistics, which are used
            for such purposes as assessing the number of visitors to the different sections of our site, what
            information is of most and least interest, determining technical design specifications, and identifying
            system performance or problem areas. </span></p>
    <p class="c47"><span class="c1">For site security purposes and to ensure that this service remains available to all
            users, the Company uses software programs to monitor network traffic to identify unauthorized attempts to
            upload or change information, or otherwise cause damage. </span></p>
    <p class="c38"><span class="c0">Policy Changes </span></p>
    <p class="c43"><span class="c1">It is our policy to post any changes we make to our privacy policy on this page. If
            we make material changes to how we treat our users&#39; personal information, we will notify</span></p>
    <p class="c52"><span class="c1">you by email to the email address specified in your account and/or through a notice
            on the Website home page. The date the privacy policy was last revised is identified at the bottom of the
            page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you,
            and for periodically visiting our Website and this </span></p>
    <p class="c17"><span class="c1">privacy policy to check for any changes. </span></p>
    <p class="c62"><span class="c0">Visitors&rsquo; GDPR Rights </span></p>
    <p class="c42"><span class="c1">If you are within the European Union, you are entitled to certain information and
            have certain rights under the General Data Protection Regulation. Those rights include: </span></p>
    <p class="c41"><span class="c1">We will retain the any information you choose to provide to us until the earlier of:
            (a) you asking us to delete the information, (b) our decision to cease using our existing data providers, or
            (c) the Company decides that the value in retaining the data is outweighed by the costs of retaining it.
        </span></p>
    <p class="c20"><span class="c1">You have the right to request access to your data that the Company stores and the
            rights to either rectify or erase your personal data. </span></p>
    <p class="c19"><span class="c1">You have the right to seek restrictions on the processing of your data. </span></p>
    <p class="c6"><span class="c1">You have the right to object to the processing of your data and the right to the
            portability of your data. </span></p>
    <p class="c5"><span class="c1">To the extent that you provided consent to the Company&rsquo;s processing of your
            personal data, you have the right to withdraw that consent at any time, without affecting the lawfulness of
            processing based upon consent that occurred prior to your withdrawal of consent. </span></p>
    <p class="c32"><span class="c1">You have the right to lodge a complaint with a supervisory authority that has
            jurisdiction over issues related to the General Data Protection Regulation. </span></p>
    <p class="c60"><span class="c1">We require only the information that is reasonably required to enter into a contract
            with you. We will not require you to provide consent for any unnecessary processing as a condition of
            entering into a contract with us.</span></p>
    <p class="c50"><span class="c1"></span></p>
    <p class="c22"><span class="c0">Contact Us </span></p>
    <p class="c53"><span class="c1">The Company welcomes your questions or comments regarding the Privacy Policy:
        </span></p>
    <p class="c53"><span class="c1">Marj inc.</span></p>
    <p class="c10"><span class="c1">Email Address: </span><span class="c9">info@marjsystems.com</span></p>
    <p class="c3"><span class="c1">Effective as of </span><span class="c9">2021 Oct 12</span></p>
        </Row>
      </Container>
        </div>
        </section>
 
    </>
  );
};

export default PrivacyTerms;