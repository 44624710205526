
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col, Container
} from "reactstrap";
import { react } from "@babel/types";
import { useState, useEffect, useContext } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

const TermsOfService = () => {
 

  return (
    <>
        <section className="relative py-20">
            <div className="container mx-auto ">
     <Container className="">
        <Row>
          <p class="c68"><span class="c2"><h1>Terms of Use</h1></span></p>
        </Row>
        </Container>
      <Container className="">
        <Row>
    <p class="c34"><span class="c0">The following Terms of Use are entered into by and between You and </span><span
            class="c7">MARJ inc. </span><span class="c0">(&ldquo;</span><span class="c3">Company</span><span
            class="c0">&rdquo;, &ldquo;</span><span class="c3">we</span><span class="c0">&rdquo;, or &ldquo;</span><span
            class="c3">us</span><span class="c0">&rdquo;). </span></p>
    <p class="c38"><span class="c0">The following terms and conditions, together with any documents they expressly
            incorporate by reference (collectively, these &ldquo;</span><span class="c3">Terms of Use</span><span
            class="c0">&rdquo;), govern your access to and use of </span><span class="c7">www.blurting.ai</span><span
            class="c0">, including any content, functionality and services offered on or through </span><span
            class="c7">www.blurting.ai</span><span class="c0">&nbsp;(the &ldquo;</span><span
            class="c3">Website</span><span class="c0">&rdquo;), whether as a guest or a registered user. </span></p>
    <p class="c23"><span class="c0">Please read the Terms of Use carefully before you start to use the Website.
        </span><span class="c3">By using the Website or by clicking to accept or agree to the Terms of Use when this
            option is made available to you, you accept and agree to be bound and abide by these Terms of Use and our
            Privacy Policy, incorporated herein by reference. </span><span class="c0">If you do not want to agree to
            these Terms of Use including the agreements incorporated by reference herein, you must not access or use the
            Website. </span></p>
    <p class="c42"><span class="c0">This Website is offered and available to users who are 18 years of age or older. By
            using this Website, you represent and warrant that you are of legal age to form a binding contract with the
            Company and meet all of the foregoing eligibility requirements. If you do not meet all of these
            requirements, you must not access or use the Website. </span></p>
    <p class="c14"><span class="c3">Changes To the Terms Of Use </span></p>
    <p class="c29"><span class="c0">We may revise and update these Terms of Use from time to time in our sole
            discretion. All changes are effective immediately when we post them, and apply to all access to and use of
            the Website thereafter. Your continued use of the Website following the posting of revised Terms of Use
            means that you accept and agree to the changes. You are expected to check this page from time to time so you
            are aware of any changes, as they are binding on you. </span></p>
    <p class="c17"><span class="c3">Privacy </span></p>
    <p class="c77"><span class="c0">Your use of the Website is also subject to the Company&rsquo;s Privacy Policy.
            Please review our Privacy Policy, which also governs the Website and informs users of our data collection
            practices. Your agreement to the Privacy Policy is hereby incorporated into these Terms of Use. </span></p>
    <p class="c15"><span class="c3">Disclaimer </span></p>
    <p class="c22"><span class="c0">Your use of the Website is also subject to the Company&rsquo;s Disclaimer. Please
            review our Disclaimer, which also governs the Website and informs users of various limitations regarding the
            information provided on the Website. Your agreement to the Disclaimer is hereby incorporated into these
            Terms of Use.</span></p>
    <p class="c79"><span class="c3">Accessing The Website And Account Security </span></p>
    <p class="c43"><span class="c0">We reserve the right to withdraw or amend this Website and any service or material
            we provide on the Website in our sole discretion without notice. We will not be liable if for any reason all
            or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict
            access to some parts of the Website, or the entire Website, to users, including registered users. </span>
    </p>
    <p class="c44"><span class="c0">To access the Website or some of the resources it offers, you may be asked to
            provide certain registration details or other information. It is a condition of your use of the Website and
            any resources downloaded from the Website that all the information you provide on the Website is correct,
            current, and complete. You agree that all information you provide to register with this Website or
            otherwise, including but not limited to through the use of any interactive features on the Website, is
            governed by our Privacy Policy, and you consent to all actions we take with respect to your information
            consistent with our Privacy Policy. </span></p>
    <p class="c40"><span class="c0">If you choose, or are provided with, a user name, password or any other piece of
            information as part of our security procedures, you must treat such information as confidential, and you
            must not disclose it to any other person or entity. You also acknowledge that your account is personal to
            you and agree not to provide any other person with access to this Website or portions of it using your user
            name, password or other security information. You agree to notify us immediately of any unauthorized access
            to or use of your user name or password or any other breach of security. You also agree to ensure that you
            exit from your account at the end of each session. You should use particular caution when accessing your
            account from a public or shared computer so that others are not able to view or record your password or
            other personal information. </span></p>
    <p class="c19"><span class="c0">We have the right to disable any user name, password or other identifier, whether
            chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in
            our opinion, you have violated any provision of these Terms of Use. </span></p>
    <p class="c15"><span class="c3">No Unlawful Or Prohibited Use And Intellectual Property </span></p>
    <p class="c66"><span class="c0">You are granted a non-exclusive, non-transferable, revocable license to access and
            use the Website and the resources available for download from the Website strictly in accordance with these
            Terms of Use. </span></p>
    <p class="c55"><span class="c0">As a condition of your use of the Website, you warrant to the Company that you will
            not use the Website or any of the resources available for download from the Website for any purpose that is
            unlawful or prohibited by these Terms. You may not use the Website or any of the resources available for
            download from the Website in any manner that could damage, disable, overburden, or impair the Website or
            interfere with any other party&rsquo;s use and enjoyment of the Website. You may not obtain or attempt to
            obtain any materials or</span></p>
    <p class="c61"><span class="c0">information through any means not intentionally made available or provided for
            through the Website. </span></p>
    <p class="c28"><span class="c0">All content included as part of the Service, such as text, graphics, logos, images,
            as well as the compilation thereof, and any software used on the Website, is the property of the Company or
            its suppliers and protected by copyright and other laws that protect intellectual property and proprietary
            rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other
            restrictions contained in any such content and will not make any changes thereto. </span></p>
    <p class="c56"><span class="c0">You will not modify, publish, transmit, reverse engineer, participate in the
            transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part,
            found on the Website or any of the resources available for download from the Website. </span></p>
    <p class="c6"><span class="c0">The Company content is not for resale. Your use of the Website or any of the
            resources available for download from the Website does not entitle you to make any unauthorized use of any
            protected content, and in particular you will not delete or alter any proprietary rights or attribution
            notices in any content. You will use protected content solely for your individual use, and will make no
            other use of the content without the express written permission of the Company and the copyright owner. You
            agree that you do not acquire any ownership rights in any protected content. We do not grant you any
            licenses, express or implied, to the intellectual property of the Company or our licensors except as
            expressly authorized by these Terms. </span></p>
    <p class="c52"><span class="c0">The Company name, the Company logo, the Company slogan, and all related names,
            logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or
            licensors. You must not use such marks without the prior written permission of the Company. All other names,
            logos, product and service names, designs and slogans on this Website are the trademarks of their respective
            owners. </span></p>
    <p class="c17"><span class="c3">For Educational And Informational Purposes Only </span></p>
    <p class="c2"><span class="c0">As set forth more fully in the Disclaimer, the information contained on this Website
            and the resources available for download through this Website are for educational and informational purposes
            only. The information contained on this Website and the resources available for download through this
            Website is not intended as, and shall not be understood or construed as legal, financial, tax, medical,
            health, or any other professional advice. </span></p>
    <p class="c30"><span class="c3">Accuracy And Personal Responsibility </span></p>
    <p class="c45"><span class="c0">As set forth more fully in the Disclaimer, we have done our best to ensure that the
            information provided on this Website and the resources available for download are accurate and provide
            valuable information, but we cannot guarantee the accuracy of the information. Neither the Company nor any
            of its owners or employees shall be held liable</span></p>
    <p class="c21"><span class="c0">or responsible for any errors or omissions on this Website or for any damage you may
            suffer as a result of failing to seek competent advice from a professional who is familiar with your
            situation. </span></p>
    <p class="c32"><span class="c0">By using this Website, you accept personal responsibility for the results of your
            actions. You agree to take full responsibility for any harm or damage you suffer as a result of the use, or
            non-use, of the information available on this Website or the resources available for download from this
            Website. You agree to use judgment and conduct due diligence before taking any actions or implementing any
            plans or policy suggested or recommended on this Website. </span></p>
    <p class="c15"><span class="c3">No Guarantees As To Results </span></p>
    <p class="c35"><span class="c0">As set forth more fully in the Disclaimer, you agree that the Company has not made
            any guarantees about the results of taking any action, whether recommended on this Website or not. The
            Company provides educational and informational resources that are intended to help users of this Website
            succeed. You nevertheless recognize that your ultimate </span></p>
    <p class="c24"><span class="c0">success or failure will be the result of your own efforts, your particular
            situation, and innumerable other circumstances beyond the control and/or knowledge of the Company. </span>
    </p>
    <p class="c47"><span class="c0">You also recognize that prior results do not guarantee a similar outcome. Thus, the
            results obtained by others - whether clients of the Company or otherwise - applying the principles set out
            in this Website are no guarantee that you or any other person or entity will be able to obtain similar
            results. </span></p>
    <p class="c17"><span class="c3">Email And Other Electronic Communications </span></p>
    <p class="c74"><span class="c0">Visiting the Website or sending emails to the Company constitutes electronic
            communications. You consent to receive electronic communications and you agree that all agreements, notices,
            disclosures, and other communications that we provide to you electronically, via email and on the Website,
            satisfy any legal requirement that such communications be in writing. </span></p>
    <p class="c51"><span class="c0">We would be pleased to communicate with you by e-mail, and there are various places
            on this Website that provide you the ability to send an electronic communication to the Company. Any such
            email or other electronic communication, however, does not create a business relationship or any contractual
            relationship. As set forth more fully in our Privacy Policy, we will take reasonable steps to ensure that
            any communications remain confidential, but we cannot guarantee the security of such communications and
            cannot guarantee that we would not be required to disclose such communications as a result of a court order.
        </span></p>
    <p class="c17"><span class="c3">Use Of Communication Services</span></p>
    <p class="c25"><span class="c0">The Website may contain bulletin board services, chat areas, news groups, forums,
            communities, personal web pages, calendars, blog comment sections and/or other message or communication
            facilities designed to enable you to communicate with the public at large or with a group (collectively,
            &ldquo;</span><span class="c3">Communication Services</span><span class="c0">&rdquo;), you agree to use the
            Communication Services only to post, send and receive messages and material that are proper and related to
            the particular Communication Service. </span></p>
    <p class="c72"><span class="c0">By way of example, and not as a limitation, you agree that when using a
            Communication Service, you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal
            rights (such as rights of privacy and publicity) of others; publish, post, upload, distribute or disseminate
            any inappropriate, profane, defamatory, infringing, obscene, indecent or </span></p>
    <p class="c69"><span class="c0">unlawful topic, name, material or information; upload files that contain software or
            other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own
            or control the rights thereto or have received all necessary consents; upload files that contain viruses,
            corrupted files, or any other similar software or programs that may damage the operation of another&#39;s
            computer; advertise or offer to sell or buy any goods or services for any business purpose, unless such
            Communication Service specifically allows such messages; conduct or forward surveys, contests, pyramid
            schemes or chain letters; download any file posted by another user of a Communication Service that you know,
            or reasonably should know, cannot be legally distributed in such manner; falsify or delete any author
            attributions, legal or other proper notices or proprietary designations or labels of the origin or source of
            software or other material contained in a file that is uploaded, restrict or inhibit any other user from
            using and enjoying the Communication Services; violate any code of conduct or other guidelines which may be
            applicable for any particular Communication Service; harvest or otherwise collect information about others,
            including e-mail addresses, without their consent; violate any applicable laws or regulations. </span></p>
    <p class="c11"><span class="c0">The Company has no obligation to monitor the Communication Services. However, the
            Company reserves the right to review materials posted to a Communication Service and to remove any materials
            in its sole discretion. The Company reserves the right to terminate your access to any or all of the
            Communication Services at any time without notice for any reason whatsoever. </span></p>
    <p class="c12"><span class="c0">The Company reserves the right at all times to disclose any information as necessary
            to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post
            or to remove any information or materials, in whole or in part, in the Company&rsquo;s sole discretion.
        </span></p>
    <p class="c70"><span class="c0">Always use caution when giving out any personally identifying information about
            yourself in any Communication Service. The Company does not control or endorse the content, messages or
            information found in any Communication Service and, therefore, the Company specifically disclaims any
            liability with regard to the Communication Services and any actions resulting from your participation in any
            Communication</span></p>
    <p class="c31"><span class="c0">Service. Managers and hosts are not authorized the Company spokespersons, and their
            views do not necessarily reflect those of the Company. </span></p>
    <p class="c54"><span class="c0">Materials uploaded to a Communication Service may be subject to posted limitations
            on usage, reproduction and/or dissemination. You are responsible for adhering to such limitations if you
            upload the materials. </span></p>
    <p class="c17"><span class="c3">Materials Provided To The Website </span></p>
    <p class="c63"><span class="c0">The Company does not claim ownership of the materials you provide to the Website
            (including feedback and suggestions) or post, upload, input or submit to any Website or our associated
            services (collectively &ldquo;</span><span class="c3">Submissions</span><span class="c0">&rdquo;). However,
            by posting, uploading, inputting, providing, or submitting your Submission you are granting the Company, our
            affiliated companies, and necessary sub-licensees permission to use your Submission in connection with the
            operation of their Internet businesses including, without limitation, the rights to: copy, distribute,
            transmit, publicly display, publicly perform, reproduce, edit, translate, and reformat your Submission; and
            to publish your name in connection with your Submission. </span></p>
    <p class="c8"><span class="c0">No compensation will be paid with respect to the use of your Submission, as provided
            herein. The Company is under no obligation to post or use any Submission you may provide and may remove any
            Submission at any time in the Company&rsquo;s sole discretion. </span></p>
    <p class="c64"><span class="c0">By posting, uploading, inputting, providing, or submitting your Submission you
            warrant and represent that you own or otherwise control all of the rights to your Submission as described in
            this section including, without limitation, all the rights necessary for you to provide, post, upload, input
            or submit the Submissions. </span></p>
    <p class="c15"><span class="c3">Links To Third Party Websites And Services </span></p>
    <p class="c48"><span class="c0">The Website may contain links to other Websites (&ldquo;</span><span
            class="c3">Linked Websites</span><span class="c0">&rdquo;). The Linked Websites are not under the control of
            the Company and the Company is not responsible for the contents of any Linked Website, including without
            limitation any link contained in a Linked Website, or any changes or updates to a Linked Website. The
            Company is providing these links to you only as a convenience, and the inclusion of any link does not imply
            endorsement by the Company of the Website or any association with its operators. </span></p>
    <p class="c80"><span class="c0">Certain services made available via the Website are delivered by third-party
            Websites and organizations. By using any product, service, or functionality originating from the Website,
            you hereby acknowledge and consent that the Company may share such information and data with any third party
            with whom the Company has a contractual relationship to provide the requested product, service or
            functionality on behalf of the Website&rsquo;s users and customers.</span></p>
    <p class="c10"><span class="c3"></span></p>
    <p class="c17"><span class="c3">Use Of Templates And Forms </span></p>
    <p class="c4"><span class="c0">The Company provides various templates and/or forms for download and/or sale on this
            Website. The Company grants you a limited, personal, non-exclusive, non-transferable license to use our
            templates and/or forms for your own personal or internal business use. Except as otherwise provided, you
            acknowledge and agree that you have no right to modify, edit, copy, reproduce, create derivative works of,
            reverse engineer, alter, enhance or in any way exploit any of the templates and/or forms in any manner,
            except for modifications in filling out the templates and/or forms for your authorized use. </span></p>
    <p class="c33"><span class="c0">By ordering or downloading Forms, you agree that the Forms you purchase or download
            may only be used by you for your personal or business use and may not be sold or redistributed without the
            express written consent of the Company. </span></p>
    <p class="c17"><span class="c3">Use of Paid Courses, Programs, and Associated Material </span></p>
    <p class="c4"><span class="c0">The Company from time-to-time provides various courses, programs, and associated
            material for sale on this Website. The Company grants you a limited, personal, non-exclusive,
            non-transferable license to use our courses, programs, and associated material (collectively the
            &ldquo;</span><span class="c3">Courses</span><span class="c0">&rdquo;) for your own personal or internal
            business use. Except as otherwise provided, you acknowledge and agree that you have no right to modify,
            edit, copy, reproduce, create derivative works of, reverse engineer, alter, enhance or in any way exploit
            any of the Courses in any manner. </span></p>
    <p class="c57"><span class="c0">By ordering or participating in Courses, you agree that the Courses you purchase or
            download may only be used by you for your personal or business use and may not be sold or redistributed
            without the express written consent of the Company. </span></p>
    <p class="c16"><span class="c0">By ordering or participating in Courses, you further agree that you shall not create
            any derivative work based upon the Courses and you shall not offer any competing products or services based
            upon any information contained in the Courses. </span></p>
    <p class="c14"><span class="c3">Guests </span></p>
    <p class="c75"><span class="c0">The Company may, from time to time, provide information from a third party in the
            form of a podcast guest interview, interview on other platform, guest blog post, or other medium. The
            Company does not control the information provided by such third-party guests, is not responsible for
            investigating the truth of any information provided, and cannot guarantee the veracity of any statements
            made by such guests. </span></p>
    <p class="c1"><span class="c0">Individuals who agree to appear as guests on any podcast offered by the Company agree
            to transfer all intellectual property rights they may have in any such interviews to the Company and further
            provide a license to any rights they are unable to assign. </span></p>
    <p class="c13"><span class="c0">Upon determining that you are entitled to a refund pursuant to this policy, the
            Company will promptly issue an instruction to its payment processor to issue the refund. The Company does
            not control its payment processor and will not be able to expedite any refunds. </span></p>
    <p class="c65"><span class="c3">No Warranties</span></p>
    <p class="c5"><span class="c0">THE COMPANY MAKES NO WARRANTIES REGARDING THE PERFORMANCE OR OPERATION OF THIS
            WEBSITE. THE COMPANY FURTHER MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO
            THE INFORMATION, CONTENTS, MATERIALS, DOCUMENTS, PROGRAMS, PRODUCTS, BOOKS, OR SERVICES INCLUDED ON OR
            THROUGH THIS WEBSITE. TO THE FULLEST EXTENT PERMISSIBLE UNDER THE LAW, THE COMPANY DISCLAIMS ALL WARRANTIES,
            EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
        </span></p>
    <p class="c15"><span class="c3">Limitation of Liability </span></p>
    <p class="c68"><span class="c0">YOU AGREE TO ABSOLVE THE COMPANY OF ANY AND ALL LIABILITY OR LOSS THAT YOU OR ANY
            PERSON OR ENTITY ASSOCIATED WITH YOU MAY SUFFER OR INCUR AS A RESULT OF USE OF THE INFORMATION CONTAINED ON
            THIS WEBSITE AND/OR THE RESOURCES YOU MAY DOWNLOAD FROM THIS WEBSITE. YOU AGREE THAT THE COMPANY SHALL NOT
            BE LIABLE TO YOU FOR ANY TYPE OF DAMAGES, INCLUDING DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EQUITABLE, OR
            CONSEQUENTIAL LOSS OR DAMAGES FOR USE OF THIS WEBSITE. </span></p>
    <p class="c50"><span class="c0">THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH
            THE WEBSITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
            INFORMATION HEREIN. THE COMPANY AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE WEBSITE AT
            ANY TIME. </span></p>
    <p class="c49"><span class="c0">THE COMPANY AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY,
            RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
            RELATED GRAPHICS CONTAINED ON THE WEBSITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
            LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED &quot;AS IS&quot;
            WITHOUT WARRANTY OR CONDITION OF ANY KIND. THE COMPANY AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES
            AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING
            ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
            NON-INFRINGEMENT. </span></p>
    <p class="c36"><span class="c0">TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE COMPANY
            AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL
            DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS,
            ARISING OUT</span></p>
    <p class="c73"><span class="c0">OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE WEBSITE, WITH THE
            DELAY OR INABILITY TO USE THE WEBSITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES,
            OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE WEBSITE, OR
            OTHERWISE ARISING OUT OF THE USE OF THE WEBSITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
            LIABILITY OR OTHERWISE, EVEN IF THE COMPANY OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF
            DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
            CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH
            ANY PORTION OF THE WEBSITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
            DISCONTINUE USING THE WEBSITE. </span></p>
    <p class="c15"><span class="c3 c18">Dispute Resolution </span></p>
    <p class="c71"><span class="c0 c18">You hereby expressly waive any and all claims you may have, now or in the
            future, arising out of or relating to this Website, the Company, any and all contracts you enter into with
            the Company, and any and all of the Company&rsquo;s products and services. To the extent that you attempt to
            assert any such claim, you hereby expressly agree to present such claim only in the state or federal courts
            that are geographically nearest to </span><span class="c7 c18">Toronto</span><span class="c0 c18">,
        </span><span class="c7 c18">Ontario</span><span class="c0 c18">. </span></p>
    <p class="c39"><span class="c3">International Users </span></p>
    <p class="c76"><span class="c0">The Service is controlled, operated and administered by the Company from our offices
            within the USA. If you access the Service from a location outside the USA, you are</span></p>
    <p class="c59"><span class="c0">responsible for compliance with all local laws. You agree that you will not use the
            Company Content accessed through the Website in any country or in any manner prohibited by any applicable
            laws, restrictions or regulations. </span></p>
    <p class="c17"><span class="c3">Indemnification </span></p>
    <p class="c58"><span class="c0">You agree to indemnify, defend, and hold harmless the Company, its officers,
            directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including
            reasonable attorneys&rsquo; fees) relating to or arising out of your use of or inability to use the Website
            or services, any user postings made by you, your violation of any terms of this Agreement or your violation
            of any rights of a third party, or your violation of any applicable laws, rules or regulations. The Company
            reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise
            subject to indemnification by you, in which event you will fully cooperate with the Company in asserting any
            available defenses. </span></p>
    <p class="c60"><span class="c3">Termination And Access Restriction </span></p>
    <p class="c9"><span class="c0">The Company reserves the right, in its sole discretion, to terminate your access to
            the Website and the related services or any portion thereof at any time, without notice. To the maximum
            extent permitted by law, and you hereby consent to resolve any and all disputes arising under or related to
            this Website or the Terms of Use pursuant to the </span><span class="c0">Dispute Resolution</span><span
            class="c7">&nbsp;</span><span class="c0">Clause above. Use of the Website is unauthorized in any
            jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this
            section. </span></p>
    <p class="c15"><span class="c3">No Joint Venture Or Other Relationship </span></p>
    <p class="c46"><span class="c0">You agree that no joint venture, partnership, employment, or agency relationship
            exists between you and the Company as a result of this agreement or use of the Website. The Company&rsquo;s
            performance of this agreement is subject to existing laws and legal process, </span></p>
    <p class="c26"><span class="c0">and nothing contained in this agreement is in derogation of the Company&rsquo;s
            right to comply with governmental, court, and law enforcement requests or requirements relating to your use
            of the Website or information provided to or gathered by the Company with respect to such use. If any part
            of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not
            limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or
            unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely
            matches the intent of the original provision and the remainder of the agreement shall continue in effect.
        </span></p>
    <p class="c17"><span class="c3">Entire Agreement </span></p>
    <p class="c27"><span class="c0">Unless otherwise specified herein, this agreement, along with the Privacy Policy and
            Disclaimer, constitutes the entire agreement between the user and the Company with</span></p>
    <p class="c78"><span class="c0">respect to the Website and it supersedes all prior or contemporaneous communications
            and proposals, whether electronic, oral or written, between the user and the Company with respect to the
            Website. A printed version of this agreement and of any notice given in electronic form shall be admissible
            in judicial or administrative proceedings based upon or relating to this agreement to the same extent and
            subject to the same conditions as other business documents and records originally generated and maintained
            in printed form. It is the express wish to the parties that this agreement and all related documents be
            written in English. </span></p>
    <p class="c14"><span class="c3">Changes to Terms </span></p>
    <p class="c37"><span class="c0">The Company reserves the right, in its sole discretion, to change the Terms under
            which the Website is offered. The most current version of the Terms will supersede all previous versions.
            The Company encourages you to periodically review the Terms to stay informed of our updates. </span></p>
    <p class="c14"><span class="c3" >Contact Us </span></p>
    <p class="c14"><span class="c3" ><br/></span></p>

    <p class="c62"><span class="c0">The Company welcomes your questions or comments regarding the Terms: </span></p>
    <p class="c65 c67"><span class="c7">Marj inc.</span></p>
    <p class="c20"><span class="c0">Email Address:</span><span class="c7">&nbsp;info@marjsystems.com</span><span
            class="c0">&nbsp;</span></p>
    <p class="c20"><span class="c0">Effective as of </span><span class="c7">10 October 2021</span></p>
        </Row>
      </Container>
                        </div>
                        </section>
 
    </>
  );
};

export default TermsOfService;