import React, {useState, useEffect, useContext, useRef} from 'react'
import Topbar from "components/Topbar.js";
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { BrowserRouter as Router, useHistory, useLocation, Link } from "react-router-dom";
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
const columns: GridColDef[] = [
    {
      field: 'topic',
      headerName: 'Topic',
      flex: 1,
    },
    {
      field: 'sub_topic',
      headerClassName: ' font-bold',
      headerName: 'Sub Topic',
      flex: 1,
    },
    {
      field: 'title',
      headerClassName: ' font-bold',
      headerName: 'Title',
      wrap:"true",
      flex: 1,
    },
    {
      field: 'tags',
      headerClassName: ' font-bold',
      headerName: 'Tags',
      flex: 1,

    },
    {
      field: 'recall',
      type: 'number',
      headerClassName: ' font-bold',
      headerName: 'Score',
      flex: 1,
    },
    {
      type: 'datetime',
      field: 'updated_time',
      headerClassName: ' font-bold',
      headerName: 'Last Blurt',
      flex: 1,
    },
    
  ];
 


export default function Main() {


  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    axios.get(window.$server_address + '/get_nuggets')
      .then(response => {
        if (response.data.result == 1){
          setData(response.data.data);
          console.log(response.data)
  
        } else if(response.data.result == -2) { 
          history.push("/landing")

        }

        setLoading(false)
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

 // Define the state for the filter and column sort state
 const [filterModel, setFilterModel] = useState(  {items: [
  { columnField: 'Age', operatorValue: '>=', value: '44' }
]});
 const [sortModel, setSortModel] = useState( [
  { field: 'ID', sort: 'desc' }
]);

 // Retrieve the filter and column sort state from localStorage on mount
 useEffect(() => {
   const filterState = localStorage.getItem("filterModel");
   const sortState = localStorage.getItem("sortModel");

   if (filterState) {
     setFilterModel(JSON.parse(filterState));
   }

   if (sortState) {
     setSortModel(JSON.parse(sortState));
   }
 }, []);

 // Save the filter and column sort state to localStorage whenever it changes
 useEffect(() => {
   localStorage.setItem("filterModel", JSON.stringify(filterModel));
   localStorage.setItem("sortModel", JSON.stringify(sortModel));
 }, [filterModel, sortModel]);


  let history = useHistory();
    
    return (
        <div>
            <Topbar/>
            <section className="relative py-20">
            <div className="container mx-auto ">
                <div className="flex flex-wrap  text-center ">
                <div className="ext-left mt-6 py-5">
                        <button
                        className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        onClick={(e)=>{ history.push("/newnugget")}}
                        style={{ transition: "all .15s ease" }}> New Nugget
                        </button>

                        {/* <button
                        className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}> Save
                        </button>

                        <button
                        className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}> Delete
                        </button> */}

                    </div>
                    
                    {loading &&                 
                     <Stack spacing={1} style={{ width: '100%' }}>
                      <Skeleton variant="rectangular" width='100%' height={60} />
                      <Skeleton variant="rectangular" width='100%' height={60} />
                      <Skeleton variant="rectangular" width='100%' height={60} />
                      <Skeleton variant="rectangular" width='100%' height={60} />
                      <Skeleton variant="rectangular" width='100%' height={60} />
                    </Stack>}
                    {!loading && data && data.length === 0 && 
                      <img src="https://img.freepik.com/premium-vector/hand-drawn-vector-drawing-landscape-with-start-sign-path-sun-black-white_725846-461.jpg?w=2000"
                      alt="No data available"></img>
                    }
                    {!loading &&  data && data.length > 0  &&
        
                      <Box style={{ height: 600, width: '100%' }}>
                          <DataGrid
                              columnBuffer={40}
                              rows={data}
                              columns={columns}
                              getRowId={(row) => row.nugget_id} 
                              headerStyle={{ backgroundColor: 'blue', color: 'white', fontWeight: 'bold' }}
                              initialState={{
                              pagination: {
                                  paginationModel: {
                                  pageSize: 30,
                                  },
                              },
                              }}
                              rowClassName="hover:cursor-pointer"
                              pageSizeOptions={[5]}
                              filterModel={filterModel}
                              onFilterModelChange={(model) => {setFilterModel(model); console.log(model)}}
                              sortModel={sortModel}
                              onSortModelChange={(model) =>{console.log(model); setSortModel(model)}}
                              // disableRowSelectionOnClick
                              onRowClick={(params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
                                  event.defaultMuiPrevented = true;
                                  console.log(params.row.id)
                                  history.push("/nugget/" + params.row.nugget_id)
                                }}
                          />
                      </Box>
                    }
                    



                </div>
            </div>
            </section>
        </div>
    )
}