import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ReactGA from "react-ga4";
import "@fortawesome/fontawesome-free/css/all.min.css";

import Landing from "views/Landing.js";
import Main from "views/Main.js";
import Blurt from "views/Blurt.js";
import NewBlurt from "views/NewBlurt.js";
import PrivacyTerms from "views/PrivacyTerms";
import TermsOfService from "views/TermsOfService";

window.$server_address = '' //'http://localhost:5000' 
ReactGA.initialize("G-94TV52RLWC");
ReactGA.send({ hitType: "pageview", page: window.location.pathname , title: "Page View" });
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path='/landing' component={Landing} />
      <Route path='/main' component={Main} />
      <Route path='/newnugget' component={NewBlurt} />
      <Route path='/nugget' component={Blurt} />
      <Route path='/privacyterms' component={PrivacyTerms} />
      <Route path='/termsofservices' component={TermsOfService} />
      <Redirect from='/' to='/landing' />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
